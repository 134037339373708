import React, {FC} from "react";

import {
  Container,
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import "filepond/dist/filepond.min.css";
import {useTranslation} from "react-i18next";
import MessageWithIcon from "../../MessageWithIcon";
import {ErrorOutline, InfoOutlined} from "@mui/icons-material";
import {useRecoilState, useRecoilValue} from "recoil";
import {azureFilesSelectedByUserToUploadAtom, FileType, UploadFile,} from "../../../providers/azure/atoms";
import {
  BOUYGUES_BLACK,
  BOUYGUES_DARK_BLUE,
  BOUYGUES_LIGHT_GREY,
  BOUYGUES_ORANGE,
  BOUYGUES_WHITE,
} from "../../../bouyguesTheme";
import {SelectChangeEvent} from "@mui/material/Select/SelectInput";
import FilenameWithSize from "../../FilenameWithSize/FilenameWithSize";
import {isThereForbiddenExtensionSelector} from "../../../providers/azure/selectors";

/**
 * Component to select files and update recoil state
 */
const ProxyMasterMapping: FC = () => {
  console.debug("ProxyMasterMapping");
  const { t } = useTranslation();
  const [azureFilesSelectedByUserToUpload, setAzureFilesSelectedByUserToUploadAtom] =
    useRecoilState(azureFilesSelectedByUserToUploadAtom);
  const isThereForbiddenExtension = useRecoilValue(isThereForbiddenExtensionSelector);

  const noMappedMasterSelectOption = t("upload-page-proxy-master-mapping-file-no-mapped-master");

  /**
   *
   * For an upload file without mappedMasterFilename, we display all master upload file not already mapped
   * For an upload file with a mappedMasterFilename we provide the corresponding upload file to let the select display the value
   * And we always add the no mapped master option to be able to clean the reset the input
   * @param uploadFile upload file to get the list of options to display in the master mapping select
   */
  const computeFilenamesOptionsToDisplayInMasterMappingSelect = (
    uploadFile: UploadFile,
  ): string[] => {
    let computedFilenamesOptionsToDisplayInMasterMappingSelect: string[];
    computedFilenamesOptionsToDisplayInMasterMappingSelect = azureFilesSelectedByUserToUpload
      ?.filter(
        (currentFile) =>
          currentFile?.fileType === FileType.MASTER &&
          !azureFilesSelectedByUserToUpload?.some(
            (currentFileToLookForInMappedMasterFileAttribute) =>
              // The current file can have a mapped master
              currentFileToLookForInMappedMasterFileAttribute?.file?.name !==
                uploadFile?.file?.name &&
              currentFileToLookForInMappedMasterFileAttribute?.mappedMasterFile?.file?.name ===
                currentFile?.file?.name,
          ),
      )
      .map((currentFile) => currentFile?.file?.name);
    computedFilenamesOptionsToDisplayInMasterMappingSelect.push(noMappedMasterSelectOption);
    return computedFilenamesOptionsToDisplayInMasterMappingSelect;
  };

  /**
   * Allow to update the state of the UploadFile to correctly type it
   *
   * @param event of toggle button
   * @param uploadFile
   */
  const handleFileTypeChange = (event: React.MouseEvent<HTMLElement>, uploadFile: UploadFile) => {
    // @ts-ignore
    const action: keyof typeof FileType = event?.target?.value;
    const newFileType = action === FileType.PROXY ? FileType.PROXY : FileType.MASTER;
    console.debug(
      "[ProxyMasterMapping][handleFileTypeChange] uploadFile: %o, newFileType: %o",
      uploadFile,
      newFileType,
    );
    const proxyFileWithThisFileAsMappedMaster = azureFilesSelectedByUserToUpload?.find(
      (currentFile) => currentFile?.mappedMasterFile?.file?.name === uploadFile?.file?.name,
    );
    console.debug(
      "[ProxyMasterMapping][handleFileTypeChange] proxyFileWithThisFileAsMappedMaster: %o",
      proxyFileWithThisFileAsMappedMaster,
    );
    const newAzureFilesSelectedByUserToUpload = [...azureFilesSelectedByUserToUpload].map(
      (currentUploadFile) => {
        // Update the current file with its new file type
        if (currentUploadFile.file.name === uploadFile?.file?.name) {
          // If the file is tagged to Master then it can't anymore have a Master mapped to it
          const newMappedMasterFile =
            newFileType === FileType.MASTER ? undefined : uploadFile?.mappedMasterFile;
          const updatedUploadFile: UploadFile = {
            ...currentUploadFile,
            fileType: newFileType,
            mappedMasterFile: newMappedMasterFile,
          };
          return updatedUploadFile;
        }
        // If the new file type switched to PROXY,
        // then make sure that it is not referenced in the mappedMasterFilename attribute of another proxy file
        // proxyFileWithThisFileAsMappedMaster won't be undefined if so
        else if (
          proxyFileWithThisFileAsMappedMaster &&
          currentUploadFile.file.name === proxyFileWithThisFileAsMappedMaster?.file?.name
        ) {
          const updatedUploadFile: UploadFile = {
            ...currentUploadFile,
            mappedMasterFile: undefined,
          };
          return updatedUploadFile;
        } else {
          return currentUploadFile;
        }
      },
    );

    console.debug(
      "[ProxyMasterMapping][handleFileTypeChange] newAzureFilesSelectedByUserToUpload: ",
      newAzureFilesSelectedByUserToUpload,
    );
    setAzureFilesSelectedByUserToUploadAtom(newAzureFilesSelectedByUserToUpload);
  };

  /**
   * Update the mapped master file of a proxy when the select value is changed
   * @param event
   * @param proxyUploadFile
   */
  const handleMappedMasterFileChange = (event: SelectChangeEvent, proxyUploadFile: UploadFile) => {
    const mappedMasterFilename = event?.target?.value;
    const mappedMasterFile = azureFilesSelectedByUserToUpload?.find(
      (azureFileSelectedByUserToUpload) =>
        azureFileSelectedByUserToUpload?.file?.name === mappedMasterFilename,
    );
    const newAzureFilesSelectedByUserToUpload = [...azureFilesSelectedByUserToUpload].map(
      (currentUploadFile) => {
        if (currentUploadFile.file.name === proxyUploadFile?.file?.name) {
          // If the file is tagged to Master then it can't anymore have a Master mapped to it
          const updatedUploadFile: UploadFile = {
            ...currentUploadFile,
            mappedMasterFile: mappedMasterFile,
          };
          return updatedUploadFile;
        } else {
          return currentUploadFile;
        }
      },
    );

    console.debug(
      "[ProxyMasterMapping][handleMappedMasterFileChange] newAzureFilesSelectedByUserToUpload: ",
      newAzureFilesSelectedByUserToUpload,
    );
    setAzureFilesSelectedByUserToUploadAtom(newAzureFilesSelectedByUserToUpload);
  };

  return (
    <Container
      className={"ProxyMasterMapping"}
      sx={{
        marginTop: "2rem",
      }}
    >
      <MessageWithIcon
        message={t("upload-page-proxy-master-mapping-info-message-1")}
        icon={<InfoOutlined />}
      />
      <MessageWithIcon
        message={t("upload-page-proxy-master-mapping-info-message-2")}
        icon={<InfoOutlined />}
      />
      <MessageWithIcon
        message={t("upload-page-proxy-master-mapping-info-message-3")}
        icon={<InfoOutlined />}
      />

      {isThereForbiddenExtension && (
        <MessageWithIcon
          message={t("upload-page-proxy-master-mapping-not-correct-extensions")}
          icon={<ErrorOutline />}
          color={BOUYGUES_ORANGE}
        />
      )}

      {/* Mapping table */}
      <TableContainer sx={{ marginTop: "3rem" }}>
        <Table
          sx={{
            minWidth: 650,
            "& th, & td": { border: `1px solid ${BOUYGUES_LIGHT_GREY}` },
            "& .MuiOutlinedInput-inputMultiline": { height: "100%" },
          }}
        >
          <TableHead sx={{ "& th": { fontWeight: "bold", textAlign: "center" } }}>
            <TableRow sx={{ "& th": { textAlign: "center" } }}>
              <TableCell style={{ width: "34%" }}>
                {t("upload-page-proxy-master-mapping-file-name").toString()}
              </TableCell>
              <TableCell style={{ width: "33%" }}>
                {t("upload-page-proxy-master-mapping-file-type").toString()}
              </TableCell>
              <TableCell style={{ width: "33%" }}>
                {t("upload-page-proxy-master-mapping-mapped-master").toString()}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {azureFilesSelectedByUserToUpload.map((azureFileSelectedByUserToUpload) => (
              <TableRow key={azureFileSelectedByUserToUpload?.file?.name}>
                <TableCell>
                  <FilenameWithSize uploadFile={azureFileSelectedByUserToUpload} />
                </TableCell>
                <TableCell sx={{ padding: 0, height: "3rem" }}>
                  <ToggleButtonGroup
                    sx={{
                      height: "100%",
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 1fr)",
                      "& button": {
                        borderRadius: 0,
                        border: 0,
                      },
                    }}
                    color="primary"
                    value={azureFileSelectedByUserToUpload?.fileType}
                    exclusive
                    onChange={(e) => handleFileTypeChange(e, azureFileSelectedByUserToUpload)}
                  >
                    <ToggleButton
                      value={FileType.PROXY}
                      sx={
                        azureFileSelectedByUserToUpload?.fileType === FileType.PROXY
                          ? {
                              backgroundColor: `${BOUYGUES_ORANGE} !important`,
                              color: `${BOUYGUES_BLACK} !important`,
                              borderRight: `1px solid ${BOUYGUES_ORANGE} !important`,
                            }
                          : {
                              color: `${BOUYGUES_BLACK} !important`,
                              borderRight: `1px solid ${BOUYGUES_LIGHT_GREY} !important`,
                            }
                      }
                    >
                      {t("upload-page-proxy-master-mapping-file-type-proxy").toString()}
                    </ToggleButton>
                    <ToggleButton
                      value={FileType.MASTER}
                      sx={
                        azureFileSelectedByUserToUpload?.fileType === FileType.MASTER
                          ? {
                              backgroundColor: `${BOUYGUES_DARK_BLUE} !important`,
                              color: `${BOUYGUES_WHITE} !important`,
                              borderRight: `1px solid ${BOUYGUES_DARK_BLUE} !important`,
                            }
                          : {
                              color: `${BOUYGUES_BLACK} !important`,
                              borderRight: `1px solid ${BOUYGUES_LIGHT_GREY} !important`,
                            }
                      }
                    >
                      {t("upload-page-proxy-master-mapping-file-type-master").toString()}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </TableCell>
                <TableCell
                  sx={
                    azureFileSelectedByUserToUpload?.fileType !== FileType.PROXY
                      ? {
                          backgroundColor: BOUYGUES_LIGHT_GREY,
                        }
                      : {
                          padding: "6px",
                          "& p": { margin: "0 !important" },
                        }
                  }
                >
                  {azureFileSelectedByUserToUpload?.fileType === FileType.PROXY && (
                    <FormControl fullWidth>
                      <Select
                        value={
                          azureFileSelectedByUserToUpload?.mappedMasterFile?.file?.name ||
                          noMappedMasterSelectOption
                        }
                        onChange={(e) =>
                          handleMappedMasterFileChange(e, azureFileSelectedByUserToUpload)
                        }
                      >
                        {computeFilenamesOptionsToDisplayInMasterMappingSelect(
                          azureFileSelectedByUserToUpload,
                        )?.map((currentSelectOption) => {
                          return (
                            <MenuItem
                              key={`mapped-master-file-${currentSelectOption}`}
                              value={currentSelectOption}
                            >
                              {currentSelectOption}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default ProxyMasterMapping;
