import React, { FC } from "react";

import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import "filepond/dist/filepond.min.css";
import { useTranslation } from "react-i18next";
import { BOUYGUES_LIGHT_GREY, BOUYGUES_ORANGE } from "../../../bouyguesTheme";
import { useRecoilValue } from "recoil";
import FilenameWithSize from "../../FilenameWithSize/FilenameWithSize";
import { PROXY_INDEXATION_FIELDS } from "../../../providers/azure/atoms";
import {
  isThereDuplicateProxyMediaIdSelector,
  proxyFilesSelector,
} from "../../../providers/azure/selectors";
import MessageWithIcon from "../../MessageWithIcon";
import { ErrorOutline, InfoOutlined } from "@mui/icons-material";
import {
  reportageWorkflowAtom,
  ReportageWorkflowId,
} from "../../../providers/applicationStep/atoms";
import ProxyIndexationFieldsContainer from "./ProxyIndexationFieldsContainer";
import { useIndexationFields } from "../../../hooks/useIndexationFields";
import { reportageCodeAtom } from "../../../providers/reportage/atoms";

const PROXY_AND_MASTER_CELL_WIDTH_IN_PERCENT = 20;
const MEDIA_ID_CELL_WIDTH_IN_PERCENT = 10;

/**
 * Component to select files and update recoil state
 */
const ProxysIndexation: FC = () => {
  console.debug("ProxysIndexation");
  const { t } = useTranslation();
  const reportageWorkflow = useRecoilValue(reportageWorkflowAtom);
  const reportageCode = useRecoilValue(reportageCodeAtom);
  const proxyFiles = useRecoilValue(proxyFilesSelector);
  const isThereDuplicateProxyMediaId = useRecoilValue(isThereDuplicateProxyMediaIdSelector);
  const { computeIndexationFieldLabel } = useIndexationFields();

  /**
   * Allow to compute the width in percent for the cell
   * The number of fields could dynamically be changed in the state if one day we add a new field
   * Also the media id field / cell is only displayed in an update workflow
   * So we have to compute the width remaining for each cell
   * ex : proxy cell: 20%, master cell 20%, mediaId cell: 10% (in an update workflow),
   * for 3 fields to display they will have a width of (100 - 20 - 20 - 10) / 3 = 16.6%
   */
  const computeIndexationFieldsCellWidthInPercent = (): number => {
    const mediaIdCellWidthForContext =
      reportageWorkflow === ReportageWorkflowId.UPDATE_REPORTAGE
        ? MEDIA_ID_CELL_WIDTH_IN_PERCENT
        : 0;
    return (
      (100 - PROXY_AND_MASTER_CELL_WIDTH_IN_PERCENT * 2 - mediaIdCellWidthForContext) /
      PROXY_INDEXATION_FIELDS.length
    );
  };

  return (
    <Container
      className={"ProxysIndexation"}
      sx={{
        marginTop: "2rem",
        maxWidth: "100% !important",
      }}
    >
      {/* Display info messages in the update workflow */}
      {reportageWorkflow === ReportageWorkflowId.UPDATE_REPORTAGE && (
        <>
          <MessageWithIcon
            message={t("upload-page-proxys-indexation-update-workflow-info-1")}
            icon={<InfoOutlined />}
          />
          <MessageWithIcon
            message={t("upload-page-proxys-indexation-update-workflow-info-2")}
            icon={<InfoOutlined />}
          />
          <MessageWithIcon
            message={t("upload-page-proxys-indexation-update-workflow-info-3")}
            icon={<InfoOutlined />}
          />

          <MessageWithIcon
            message={t("upload-page-proxys-indexation-update-workflow-reportage-code", {
              reportageCode: reportageCode,
            })}
            sx={{ marginTop: "1.5rem" }}
          />
        </>
      )}

      {reportageWorkflow === ReportageWorkflowId.UPDATE_REPORTAGE &&
        isThereDuplicateProxyMediaId && (
          <MessageWithIcon
            message={t("upload-page-proxys-indexation-update-workflow-duplicate-media-id-found")}
            icon={<ErrorOutline />}
            color={BOUYGUES_ORANGE}
            sx={{ marginTop: "1.5rem" }}
          />
        )}

      <TableContainer sx={{ marginTop: "2rem" }}>
        <Table
          sx={{
            minWidth: 650,
            "& th, & td": { border: `1px solid ${BOUYGUES_LIGHT_GREY}` },
            "& .MuiOutlinedInput-inputMultiline": { height: "100%" },
          }}
        >
          <TableHead sx={{ "& th": { fontWeight: "bold", textAlign: "center" } }}>
            <TableRow sx={{ "& th": { textAlign: "center" } }}>
              <TableCell style={{ width: `${PROXY_AND_MASTER_CELL_WIDTH_IN_PERCENT}%` }}>
                {t("upload-page-proxys-indexation-proxy").toString()}
              </TableCell>
              <TableCell style={{ width: `${PROXY_AND_MASTER_CELL_WIDTH_IN_PERCENT}%` }}>
                {t("upload-page-proxys-indexation-master").toString()}
              </TableCell>

              {reportageWorkflow === ReportageWorkflowId.UPDATE_REPORTAGE && (
                <TableCell sx={{ width: `${MEDIA_ID_CELL_WIDTH_IN_PERCENT}%` }}>
                  {t("upload-page-proxys-indexation-media-id").toString()}
                </TableCell>
              )}

              {PROXY_INDEXATION_FIELDS.map((proxyIndexationField) => {
                return (
                  <TableCell
                    key={proxyIndexationField?.labelI18nKey}
                    style={{ width: `${computeIndexationFieldsCellWidthInPercent()}%` }}
                  >
                    {computeIndexationFieldLabel(proxyIndexationField)}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {proxyFiles.map((currentProxyFile) => (
              <TableRow key={currentProxyFile?.file?.name}>
                <TableCell>
                  <FilenameWithSize uploadFile={currentProxyFile} />
                </TableCell>
                <TableCell
                  sx={
                    !currentProxyFile?.mappedMasterFile
                      ? {
                          backgroundColor: BOUYGUES_LIGHT_GREY,
                        }
                      : {}
                  }
                >
                  <FilenameWithSize uploadFile={currentProxyFile?.mappedMasterFile} />
                </TableCell>
                <ProxyIndexationFieldsContainer proxyFile={currentProxyFile} />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default ProxysIndexation;
