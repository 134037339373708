export const configuration = {
  defaultLocale: "fr",
  keycloakConfiguration: {
    url: `${process.env.REACT_APP_KEYCLOAK_URL}`,
    realm: `${process.env.REACT_APP_KEYCLOAK_REALM}`,
    clientId: `${process.env.REACT_APP_KEYCLOAK_CLIENT_ID}`,
  },
  backofficeUrl: `${process.env.REACT_APP_BACKOFFICE_BASE_URL}`,
  // eslint-disable-next-line no-restricted-globals
  backgroundImage: location.origin + "/background.jpg",
  // eslint-disable-next-line no-restricted-globals
  logoImage: location.origin + "/logo.png",
  baseValueToCalculateFileSize: 1024 as 1000 | 1024 | undefined,
  // Min validity before refreshing token if necessary
  keycloakMinValidityInSeconds: 10,
  // FORMAT LABELS ARE HARDCODED IN LABELS TOO !
  // I didn't make it dynamic to simplify code reading
  allowedProxyExtensions: ["mp4", "mov"],
  allowedMasterExtensions: ["mp4", "mov"],
  // To be able to filter thesaurus values (developed for colas-azure-upload-page)
  rootThesaurusIdsToDisplayByThesaurusInternalName: [] as {
    internalName: string;
    rootThesaurusIdsToDisplay: number[]
  }[]
};
