import React, { FC } from "react";

import { Warning } from "@mui/icons-material";
import { Container, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";

import { BOUYGUES_ORANGE, RESPONSIVE_BREAK_POINT_IN_PX } from "../../../bouyguesTheme";
import { reportageCodeAtom } from "../../../providers/reportage/atoms";
import { useReportageCode } from "../../../providers/reportage/hooks/useReportageCode";
import Loader from "../../Loader";

/**
 * Component to type the reportage code and update recoil state
 */
const InputTextReportageCode: FC = () => {
  console.debug("InputTextReportageCode");
  const { t } = useTranslation();
  const [reportageCode, setReportageCode] = useRecoilState(reportageCodeAtom);
  const { isCheckingReportageCodeExistence, isReportageCodePresent } = useReportageCode();

  const handleValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReportageCode(e?.target?.value);
  };

  return (
    <>
      {/* Display loader while typed reportage code is being checked by web service */}
      <Loader
        sx={{
          visibility: isCheckingReportageCodeExistence ? "visible" : "hidden"
        }}
      />

      <Container
        className={"InputTextReportageCode"}
        sx={{
          width: "100%",
          marginTop: "2rem",
          padding: "0 5rem 0 5rem"
        }}
      >
        <Container>
          <TextField
            data-testid={"InputTextReportageCode-input"}
            sx={{
              width: "100%",
              "& input": {
                fontSize: "2.75rem",
                [`@media (max-width: ${RESPONSIVE_BREAK_POINT_IN_PX}px)`]: {
                  fontSize: "1.3rem"
                }
              }
            }}
            variant="standard"
            placeholder={t("upload-page-reportage-code-input-placeholder").toString()}
            value={reportageCode}
            onChange={handleValueChanged}
          />
        </Container>
        <Container
          sx={{
            marginTop: "1rem"
          }}
        >
          <Typography
            sx={{
              marginTop: "1rem",
              fontSize: "1rem"
            }}
            variant="caption"
            component="p"
          >
            {t("upload-page-reportage-code-message").toString()}
          </Typography>
          {/* Display error message if reportage code is not present */}
          {!!reportageCode && !isReportageCodePresent && (
            <Typography
              sx={{
                marginTop: "0.5rem",
                fontSize: "1rem",
                color: BOUYGUES_ORANGE,
                display: "flex",
                alignItems: "center"
              }}
              variant="caption"
              component="p"
            >
              <Warning sx={{ marginRight: "0.5rem" }} />
              {t("upload-page-reportage-code-incorrect-reportage-code-message").toString()}
            </Typography>
          )}
        </Container>
      </Container>
    </>
  );
};

export default InputTextReportageCode;
