import {atom} from "recoil";
import {FIELD_TYPES, IndexationField} from "../../../models/IndexationField";
import {TreeFieldMode} from "@keepeek/refront-components/dist/Fields/TreeField/types";

export type UploadFile = {
  /**
   * The file to upload
   */
  file: File;
  /**
   * Progress of upload (between 0% and 100%)
   */
  progress?: number;

  /**
   * File type (Master file / Proxy file / Indexation file)
   */
  fileType?: FileType;

  /**
   * A proxy file can be mapped to a master file
   */
  mappedMasterFile?: UploadFile;

  /**
   * Indexation data for a proxy file
   */
  proxyIndexationData?: IndexationField[];

  /**
   * In an update workflow this allows to replace a proxy media in Keepeek
   */
  proxyMediaId?: number;
};

export const PROXY_INDEXATION_FIELDS: IndexationField[] = [
  {
    internalName: "version_linguistique",
    type: FIELD_TYPES.THESAURUS,
    labelI18nKey: "upload-page-proxys-indexation-field_version_linguistique",
    multiValued: false,
    modes: [TreeFieldMode.TREE_VIEW],
    required: true,
  }
];

export enum FileType {
  /**
   * File that is going to be stored on Azure
   */
  MASTER = "MASTER",
  /**
   * Sharing file, that is going to be uploaded on keepeek
   */
  PROXY = "PROXY",
  /**
   * Excel indexation file with data required by Bouygues Azure Import App
   */
  INDEXATION_FILE = "INDEXATION_FILE",
}

/**
 * List of files selected by the user
 */
export const azureFilesSelectedByUserToUploadAtom = atom<UploadFile[]>({
  key: "azureFilesSelectedByUserToUploadAtom",
  default: [],
});

/**
 * Folder name to upload in
 * ex : Azure:/fournisseur_folder/folder_name_with_timestamp/files_are_uploaded_here
 *
 * We want to make sure that every deposit, will upload files in a new folder
 * That way the upload script, will deal with these files and the related indexation file as a separate group
 */
export const azureFolderNameToUploadInAtom = atom<string>({
  key: "azureFolderNameToUploadInAtom",
  default: getUniqueFolderNameWithTimestampToUploadInAndLogIt(),
});

export const isUploadInProgressAtom = atom<boolean>({
  key: "isUploadInProgressAtom",
  default: false,
});

export function getUniqueFolderNameWithTimestampToUploadInAndLogIt(): string {
  const date = new Date();
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  const uniqueFolderNameWithTimestampToUploadInAndLogIt = `${year}_${month}_${day}_${hours}_${minutes}_${seconds}`;

  console.debug(
    `[azure][atoms] getUniqueFolderNameWithTimestampToUploadInAndLogIt: ${uniqueFolderNameWithTimestampToUploadInAndLogIt}`,
  );

  return uniqueFolderNameWithTimestampToUploadInAndLogIt;
}
