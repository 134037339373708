import React, {FC, ReactNode} from "react";

import {Box, Button} from "@mui/material";
import Typography from "@mui/material/Typography";
import sanitizeHtml from "sanitize-html";

import {BOUYGUES_BLACK, BOUYGUES_ORANGE, BOUYGUES_WHITE} from "../../bouyguesTheme";

export type SquareButtonWithIconProps = {
  message: string;
  icon: ReactNode;
  onClick: () => void;
};

/**
 * Component to display a large square button with an icon
 */
const SquareButtonWithIcon: FC<SquareButtonWithIconProps> = ({message, icon, onClick}) => {
  console.debug("SquareButtonWithIcon");

  return (
    <Button
      onClick={onClick}
      sx={{
        backgroundColor: `${BOUYGUES_WHITE}`,
        color: `${BOUYGUES_BLACK}`,
        border: `1px solid ${BOUYGUES_BLACK}`,
        minHeight: "15rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "0.5rem",
        "& div": {
          fontSize: "2rem"
        },
        "&:hover": {
          color: `${BOUYGUES_WHITE}`
        }
      }}
    >
      <Box sx={{"& svg": {width: "3rem", height: "3rem", color: `${BOUYGUES_ORANGE}`}}}>{icon}</Box>
      <Box sx={{marginTop: "0.5rem"}}>
        <Typography variant="caption" component="p">
          <span
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(message, {
                selfClosing: ["br"]
              })
            }}
          />
        </Typography>
      </Box>
    </Button>
  );
};

export default SquareButtonWithIcon;
