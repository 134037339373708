import {selector} from "recoil";
import {isReportageCodePresentAtom, reportageGlobalIndexationDataAtom,} from "../../reportage/atoms";
import {azureFilesSelectedByUserToUploadAtom} from "../../azure/atoms";
import {
  areAllMasterFilesMappedToOneAndOnlyOneProxyFileSelector,
  isThereDuplicateProxyMediaIdSelector,
  isThereForbiddenExtensionSelector,
  proxyFilesSelector,
} from "../../azure/selectors";

/**
 * Allow to say if the step is valid or not depending on the conditions
 * - all required fields have to be filled
 * - debutDeTournage year must be <= to finDeTournage year
 */
export const isReportageGlobalIndexationStepValidSelector = selector<boolean>({
  key: "isReportageGlobalIndexationStepValidSelector",
  get: ({get}) => {
    const reportageGlobalIndexationData = get(reportageGlobalIndexationDataAtom);

    return !reportageGlobalIndexationData?.some(
      (currentField) =>
        currentField?.required && (!currentField?.value || currentField?.value?.length === 0),
    );
  },
});

/**
 * Allow to say if the step is valid or not depending on the conditions
 * - must exist in keepeek (http request performed to the web service will check it)
 */
export const isReportageCodeStepValidSelector = selector<boolean>({
  key: "isReportageCodeStepValidSelector",
  get: ({get}) => {
    return get(isReportageCodePresentAtom);
  },
});

/**
 * Allow to say if the step is valid or not depending on the conditions
 * - minimum one file selected
 */
export const isSelectionStepValidSelector = selector<boolean>({
  key: "isSelectionStepValidSelector",
  get: ({get}) => {
    const azureFilesSelectedByUserToUpload = get(azureFilesSelectedByUserToUploadAtom);
    return !!azureFilesSelectedByUserToUpload?.length;
  },
});

/**
 * Allow to say if the step is valid or not depending on the conditions
 * - Every master files have to be mapped to one and only one proxy file
 * - Proxy files and masters have valid extension according to their types
 */
export const isProxyMasterMappingStepValidSelector = selector<boolean>({
  key: "isProxyMasterMappingStepValidSelector",
  get: ({get}) => {
    return (
      get(areAllMasterFilesMappedToOneAndOnlyOneProxyFileSelector) &&
      !get(isThereForbiddenExtensionSelector)
    );
  },
});

/**
 * Allow to say if the step is valid or not depending on the conditions
 * - Every mandatory fields are completed
 */
export const isProxysIndexationStepValidSelector = selector<boolean>({
  key: "isProxysIndexationStepValidSelector",
  get: ({get}) => {
    const proxyFiles = get(proxyFilesSelector);
    const isThereDuplicateProxyMediaId = get(isThereDuplicateProxyMediaIdSelector);
    return (
      !proxyFiles?.some(
        (proxyFile) =>
          (!proxyFile?.proxyIndexationData && !proxyFile?.proxyIndexationData) ||
          proxyFile?.proxyIndexationData?.some(
            (proxyIndexationField) =>
              proxyIndexationField?.required &&
              !proxyIndexationField?.value?.length &&
              !proxyFile?.proxyMediaId,
          ),
      ) && !isThereDuplicateProxyMediaId
    );
  },
});
