import React, { FC } from "react";
import { Container, Typography } from "@mui/material";
import InputThesaurusField from "../InputThesaurusField";
import { Control } from "react-hook-form/dist/types/form";
import InputTextField from "../InputTextField";
import InputDatePicker from "../InputDatePicker";
import { FIELD_TYPES, IndexationField } from "../../../../models/IndexationField";
import { useIndexationFields } from "../../../../hooks/useIndexationFields";

export type InputFieldContainerProps = {
  indexationField: IndexationField;
  control: Control;
  onValueChanged: (updatedField: IndexationField) => void;
  displayLabel?: boolean;
};

/**
 * Abstract component to display an input field depending on its type
 *
 * @param indexationField
 * @param control react-hook-form control
 * @param displayLabel false to only display the input
 * @param onValueChanged callback method when the value have been updated
 */
const InputFieldContainer: FC<InputFieldContainerProps> = ({
  indexationField,
  control,
  displayLabel = true,
  onValueChanged,
}) => {
  console.debug("InputFieldContainer");

  const { computeIndexationFieldLabel } = useIndexationFields();

  return (
    <Container
      className={"InputFieldContainer"}
      sx={{
        marginTop: "0.75rem",
        padding: "0 !important",
        "& input": {
          minHeight: "35px",
          paddingTop: "5px",
          paddingBottom: "5px",
        },
      }}
    >
      <Container
        sx={{
          display: "flex",
          padding: "0 !important",
          alignItems: "center",
        }}
      >
        {displayLabel && (
          <Typography
            sx={{
              fontSize: "1rem",
              minWidth: "10rem",
            }}
            variant="caption"
            component="p"
          >
            {computeIndexationFieldLabel(indexationField)}
          </Typography>
        )}
        <Container>
          {indexationField.type === FIELD_TYPES.TEXT && (
            <InputTextField indexationField={indexationField} onValueChanged={onValueChanged} />
          )}
          {indexationField.type === FIELD_TYPES.DATE && (
            <InputDatePicker indexationField={indexationField} onValueChanged={onValueChanged} />
          )}
          {indexationField.type === FIELD_TYPES.THESAURUS && (
            <InputThesaurusField
              control={control}
              indexationField={indexationField}
              multiple={indexationField?.multiValued}
              onValueChanged={onValueChanged}
            />
          )}
        </Container>
      </Container>
    </Container>
  );
};

export default InputFieldContainer;
