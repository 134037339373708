import React from "react";

import {useAuth} from "@keepeek/commons";
import {Box, Button, Container, LinearProgress, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import sanitizeHtml from "sanitize-html";

import {BOUYGUES_WHITE} from "../bouyguesTheme";
import {configuration} from "../configuration";

function LoginPage() {
  console.debug("LoginPage");
  const {authLogin, loading} = useAuth({});
  const {t} = useTranslation();

  return (
    <Box
      className={"Login"}
      sx={{
        position: "relative",
        overflow: "hidden"
      }}
    >
      {/* blurry background image */}
      <Box
        sx={{
          position: "absolute",
          top: "0",
          height: "100vh",
          width: "100vw",
          background: `url(${configuration.backgroundImage}) no-repeat 35% 40%`,
          backgroundColor: "black",
          transform: "scale(1.05)",
          zIndex: "-1"
        }}
      />
      <Box
        sx={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Box
          sx={{
            marginBottom: "0rem"
          }}
        >
          <img src={configuration.logoImage} alt={"logo"} height={"160px"}/>
        </Box>
        <Container
          sx={{
            "&&": {
              maxWidth: "480px",
              padding: "0",
              backgroundColor: BOUYGUES_WHITE,
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }
          }}
        >
          {/* Display linear loader during authentication */}
          {loading ? (
            <Box sx={{width: "100%"}}>
              <LinearProgress
                sx={{
                  height: "0.3rem",
                  "& span": {
                    height: "100%"
                  }
                }}
              />
            </Box>
          ) : (
            // Display an empty box with the same height of the loader to have no layout differences
            <Box sx={{height: "0.3rem", width: "100%"}}></Box>
          )}

          <Box
            sx={{
              padding: "3rem 1rem 3rem 1rem",
              display: "flex",
              flexDirection: "column"
            }}
          >
            <Typography
              variant={"h3"}
              sx={{
                margin: 0,
                fontSize: "2rem",
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                // span tag is present in label to be able to have the last in part in a second line
                "& span": {display: "flex", justifyContent: "center"}
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(t("login-page-title"), {
                    selfClosing: ["br", "span"]
                  })
                }}
              />
            </Typography>
            <Typography
              variant={"body1"}
              sx={{
                marginTop: "4rem",
                fontSize: "1rem",
                textAlign: "center"
              }}
            >
              {t("login-page-message-before-button").toString()}
            </Typography>
            <Button
              sx={{
                margin: "0.5rem 1rem 1rem 1rem"
              }}
              disabled={loading}
              onClick={() => {
                authLogin.login();
              }}
            >
              {t("login-page-login-button").toString()}
            </Button>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

export default LoginPage;
