import React, {FC, useEffect} from "react";

import {Add, Edit, InfoOutlined} from "@mui/icons-material";
import {Container} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import {useSetRecoilState} from "recoil";

import {reportageWorkflowAtom, ReportageWorkflowId} from "../../providers/applicationStep/atoms";
import SquareButtonWithIcon from "../SquareButtonWithIcon";
import {useResetAppToPerformANewUpload} from "../../providers/applicationStep/hooks/useResetAppToPerformANewUpload";
import MessageWithIcon from "../MessageWithIcon";

/**
 * Component to display create and update buttons displayed before stepper
 */
const CreateOrUpdateReportage: FC = () => {
  console.debug("CreateOrUpdateReportage");
  const {t} = useTranslation();
  const setReportageWorkflow = useSetRecoilState(reportageWorkflowAtom);
  const {resetAppToPerformANewUpload} = useResetAppToPerformANewUpload();

  useEffect(() => {
    console.debug("[CreateOrUpdateReportage] useEffect");
    resetAppToPerformANewUpload();
    // eslint-disable-next-line
  }, []);

  const handleCreateReportageClick = (): void => {
    console.debug("[CreateOrUpdateReportage] handleCreateReportageClick");
    setReportageWorkflow(ReportageWorkflowId.CREATE_REPORTAGE);
  };

  const handleUpdateReportageClick = (): void => {
    console.debug("[CreateOrUpdateReportage] handleUpdateReportageClick");
    setReportageWorkflow(ReportageWorkflowId.UPDATE_REPORTAGE);
  };

  return (
    <Container sx={{marginTop: "7rem"}}>
      <Typography
        sx={{
          fontSize: "1.9rem",
          textAlign: "center",
        }}
        variant="h3"
        component="h3"
      >
        {t("create-or-update-reportage-title").toString()}
      </Typography>
      {/* Informative messages */}
      <Container
        sx={{
          marginTop: "3rem",
        }}
      >
        <MessageWithIcon
          message={t("create-or-update-reportage-info-message-1")}
          icon={<InfoOutlined/>}
        />
        <MessageWithIcon
          message={t("create-or-update-reportage-info-message-2")}
          icon={<InfoOutlined/>}
        />
      </Container>
      <Container
        sx={{
          marginTop: "3rem",
          display: "flex",
          justifyContent: "space-around",
          maxWidth: "900px !important"
        }}
      >
        <SquareButtonWithIcon
          message={t("create-or-update-reportage-create-button")}
          icon={<Add/>}
          onClick={handleCreateReportageClick}
        />
        <SquareButtonWithIcon
          message={t("create-or-update-reportage-update-button")}
          icon={<Edit/>}
          onClick={handleUpdateReportageClick}
        />
      </Container>
    </Container>
  );
};

export default CreateOrUpdateReportage;
