import React, {FC} from "react";
import {InfoOutlined} from "@mui/icons-material";
import {Container} from "@mui/material";
import {FormProvider, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

import MessageWithIcon from "../../MessageWithIcon";
import {useRecoilState} from "recoil";
import {reportageGlobalIndexationDataAtom} from "../../../providers/reportage/atoms";
import InputFieldContainer from "./InputFieldContainer";
import {IndexationField} from "../../../models/IndexationField";
import {updateIndexationFields} from "../../../utils/fields";

/**
 * Component to enter data global to the new reportage
 */
const ReportageGlobalIndexation: FC = () => {
  console.debug("ReportageGlobalIndexation");
  const {t} = useTranslation();
  const reactHookFormMethods = useForm({mode: "all"});
  const {control} = reactHookFormMethods;
  const [reportageGlobalIndexationData, setReportageGlobalIndexationData] = useRecoilState(
    reportageGlobalIndexationDataAtom,
  );
  console.debug(
    "[ReportageGlobalIndexation] reportageGlobalIndexationData: ",
    reportageGlobalIndexationData,
  );

  const handleChangedValue = (updatedField: IndexationField): void => {
    console.debug("[ReportageGlobalIndexation][handleChangedValue] updatedField:", updatedField);
    const updatedReportageGlobalIndexationFields = updateIndexationFields(
      reportageGlobalIndexationData,
      updatedField,
    );
    setReportageGlobalIndexationData(updatedReportageGlobalIndexationFields);
  };

  return (
    <Container
      className={"ReportageGlobalIndexation"}
      sx={{
        marginTop: "2rem",
        maxWidth: "60rem !important",
      }}
    >

      <MessageWithIcon
        message={t("upload-page-reportage-global-indexation-message-1")}
        icon={<InfoOutlined/>}
      />

      <MessageWithIcon
        message={t("upload-page-reportage-global-indexation-message-2")}
        icon={<InfoOutlined/>}
      />

      <Container
        sx={{
          marginTop: "3rem",
          padding: "0 !important",
        }}
      >
        <FormProvider {...reactHookFormMethods}>
          {reportageGlobalIndexationData?.map((currentReportageGlobalIndexationField) => {
            return (
              <InputFieldContainer
                key={currentReportageGlobalIndexationField?.internalName}
                indexationField={currentReportageGlobalIndexationField}
                control={control}
                onValueChanged={handleChangedValue}
              />
            );
          })}
        </FormProvider>
      </Container>
    </Container>
  );
};

export default ReportageGlobalIndexation;
