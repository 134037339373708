import * as React from "react";
import { FC } from "react";

import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";

import { BOUYGUES_BLACK, BOUYGUES_WHITE, RESPONSIVE_BREAK_POINT_IN_PX } from "../../bouyguesTheme";

export enum BUTTON_DIRECTION {
  PREVIOUS = "PREVIOUS",
  NEXT = "NEXT",
}

export type StepperButtonProps = {
  direction: BUTTON_DIRECTION;
  label: string;
  onClick: () => void;
  disabled?: boolean;
};

/**
 * Component to represent stepper button (previous and next)
 */
const StepperButton: FC<StepperButtonProps> = ({ direction, label, onClick, disabled }) => {
  console.debug(`[StepperButton] direction: '${direction}', label: '${label}'`);
  return (
    <Box className={"StepperButton"}>
      <Button
        sx={{
          padding: "0.5rem 2.5rem 0.5rem 2.5rem",
          backgroundColor: "transparent",
          border: `1px solid ${BOUYGUES_BLACK}`,
          "&:hover": {
            color: BOUYGUES_WHITE
          },
          [`@media (max-width: ${RESPONSIVE_BREAK_POINT_IN_PX}px)`]: {
            padding: "0.5rem"
          }
        }}
        onClick={onClick}
        disabled={disabled}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: BUTTON_DIRECTION.PREVIOUS === direction ? "row-reverse" : "row"
          }}
        >
          <Box>
            <Typography variant={"button"} component={"span"}>
              {label}
            </Typography>
          </Box>
          {BUTTON_DIRECTION.PREVIOUS === direction ? <NavigateBefore /> : <NavigateNext />}
        </Box>
      </Button>
    </Box>
  );
};

export default StepperButton;
