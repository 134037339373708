import {createTheme} from "@mui/material";
import * as locales from "@mui/material/locale";

export const BOUYGUES_ORANGE = "#E75113";
export const BOUYGUES_BLACK = "#000000";
export const BOUYGUES_BLACK_LIGHT = "#00000080";
export const BOUYGUES_WHITE = "#ffffff";
export const BOUYGUES_LIGHT_GREY = "#f1f0ef";
export const BOUYGUES_BLUE = "#4BBECF";
export const BOUYGUES_DARK_BLUE = "#2C4965";

export const RESPONSIVE_BREAK_POINT_IN_PX = 768;
export const RESPONSIVE_VERY_SMALL_DEVICES_BREAK_POINT_IN_PX = 440;

export const FONT_FAMILY = ["Arial", "sans-serif"].join(", ");

const bouyguesTheme = createTheme(
  {
    palette: {
      primary: {
        main: BOUYGUES_BLACK,
      },
      secondary: {
        main: BOUYGUES_ORANGE,
      },
    },
    typography: {
      fontFamily: FONT_FAMILY,
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            // To avoid having button without any-space between them in tiny devices
            marginLeft: "1px",
            fontSize: "1rem",
            padding: "1rem 5rem 1rem 5rem",
            boxShadow: "none !important",
            backgroundColor: BOUYGUES_ORANGE,
            color: BOUYGUES_BLACK,
            transition: "all 0.2s linear",
            "&:hover": {
              backgroundColor: BOUYGUES_BLACK,
              color: BOUYGUES_ORANGE,
            },
            "& div.MuiBox-root": {
              lineHeight: "0.8rem",
              "& span": {
                lineHeight: "0.8rem",
              },
            },
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          ".SnackbarItem-variantWarning": {
            backgroundColor: `${BOUYGUES_ORANGE} !important`,
            color: `${BOUYGUES_BLACK} !important`,
            fontSize: "1.1rem !important",
          },
        },
      },
    },
  },
  locales["frFR"],
);

export default bouyguesTheme;
