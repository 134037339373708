import {atom} from "recoil";
import {FIELD_TYPES, IndexationField} from "../../../models/IndexationField";
import {TreeFieldMode} from "@keepeek/refront-components";

/**
 * reportage code (computed by upload script, and communicated in the final mail to be able to update a reportage later)
 * ex : 2023-04-01_12-00-00-950
 */
export const reportageCodeAtom = atom<string>({
  key: "reportageCodeAtom",
  default: "",
});

/**
 * Is set to true when the reportage code query parameter is present
 */
export const isReportageCodePresentAtom = atom<boolean>({
  key: "isReportageCodePresentAtom",
  default: false,
});

export const REPORTAGE_GLOBAL_INDEXATION_DEFAULT_DATA: IndexationField[] = [
  {
    internalName: "title",
    type: FIELD_TYPES.TEXT,
    labelI18nKey: "upload-page-reportage-field-title",
    multiValued: false,
    required: true,
  },
  {
    internalName: "producteur_executif",
    type: FIELD_TYPES.THESAURUS,
    labelI18nKey: "upload-page-reportage-field-producteur_executif",
    multiValued: false,
    required: false,
    modes: [TreeFieldMode.TREE_AUTOCOMPLETE, TreeFieldMode.TREE_VIEW],
  },
  {
    internalName: "production",
    type: FIELD_TYPES.THESAURUS,
    labelI18nKey: "upload-page-reportage-field-production",
    multiValued: true,
    required: true,
    modes: [TreeFieldMode.TREE_AUTOCOMPLETE, TreeFieldMode.TREE_VIEW],
  },
  {
    internalName: "realisateur",
    type: FIELD_TYPES.THESAURUS,
    labelI18nKey: "upload-page-reportage-field-realisateur",
    multiValued: true,
    required: false,
    modes: [TreeFieldMode.TREE_AUTOCOMPLETE, TreeFieldMode.TREE_VIEW],
  },
  {
    internalName: "lieux_geographique",
    type: FIELD_TYPES.THESAURUS,
    labelI18nKey: "upload-page-reportage-field-lieux_geographique",
    multiValued: true,
    required: false,
    modes: [TreeFieldMode.TREE_AUTOCOMPLETE, TreeFieldMode.TREE_VIEW],
  },
  {
    internalName: "date_de_diffusion",
    type: FIELD_TYPES.DATE,
    labelI18nKey: "upload-page-reportage-field-date_de_diffusion",
    multiValued: false,
    required: true,
  },
];

/**
 * Represents the data entered by the user when he creates a reportage
 */
export const reportageGlobalIndexationDataAtom = atom<IndexationField[]>({
  key: "reportageGlobalIndexationDataAtom",
  default: REPORTAGE_GLOBAL_INDEXATION_DEFAULT_DATA,
});

/**
 * Optional text for user to communicate with admin
 */
export const reportageObservationsAtom = atom<string>({
  key: "reportageObservationsAtom",
  default: "",
});
