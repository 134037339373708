import { FC } from "react";

import { Box, LinearProgress, LinearProgressProps, Paper, Theme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { SxProps } from "@mui/system/styleFunctionSx/styleFunctionSx";

import { BOUYGUES_LIGHT_GREY } from "../../bouyguesTheme";

export type LinearProgressWithLabelProps = {
  sx?: SxProps<Theme>;
  linearProgressProps: LinearProgressProps & { value: number };
  label: string;
};

/**
 * Component to display a progress bar with the progress as percent next to it
 */
const LinearProgressWithLabel: FC<LinearProgressWithLabelProps> = ({
                                                                     sx,
                                                                     linearProgressProps,
                                                                     label
                                                                   }) => {
  const { value: progress } = linearProgressProps;

  return (
    <Paper elevation={2} sx={{ padding: "0.75rem 2rem 0.75rem 2rem", ...sx }}>
      <Box>
        <Typography variant="h6" component={"h6"} sx={{ fontSize: "1rem" }}>
          {label}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            width: "100%",
            mr: 1,
            "& .MuiLinearProgress-root": {
              height: "1rem",
              backgroundColor: `${BOUYGUES_LIGHT_GREY} !important`
            }
          }}
        >
          <LinearProgress variant="determinate" color={"primary"} {...linearProgressProps} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography
            variant="caption"
            component={"span"}
            sx={{
              fontSize: "1.5rem",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}
          >{`${Math.round(progress || 0)}%`}</Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default LinearProgressWithLabel;
