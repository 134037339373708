import React from "react";

import {
  currentLocaleAtom,
  customerCommonOverrideAtom,
  FetcherMode,
  fetcherModeAtom,
  getAxiosInstanceAtom,
  getLoggerAtom,
} from "@keepeek/commons";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Axios, { AxiosInstance } from "axios";
import i18n from "i18next";
import { SnackbarProvider } from "notistack";
import ReactDOM from "react-dom/client";
import "./index.css";
import { initReactI18next } from "react-i18next";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { RecoilRoot } from "recoil";

import App from "./App";
import bouyguesTheme from "./bouyguesTheme";
import { configuration } from "./configuration";
import frTranslations from "./locales/fr.json";
import reportWebVitals from "./reportWebVitals";
import { configureLoggerForEnvironment } from "./utils/logger-utils";
import languages from "@keepeek/refront-components/dist/i18n/locales";
import componentThesaurusOverrideOptionsOut from "./refront-overrides/component-thesaurus-options-out";
// Logger configuration
configureLoggerForEnvironment(`${process.env.NODE_ENV}`);

console.debug("configuration: ", configuration);

/**
 * i18n Configuration
 */
// eslint-disable-next-line
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      // Another language could easily be added by adding a new JSON file and mapping here
      fr: {
        translation: frTranslations,
        // Used by the thesaurus choices display switch
        "refront-components": languages["fr"]["refront-components"],
      },
    },
    fallbackLng: configuration.defaultLocale,
    interpolation: {
      // react already safes from xss
      // https://www.i18next.com/translation-function/interpolation#unescape
      escapeValue: false,
    },
  })
  .catch((error) => console.error(error));

function initAxios(): AxiosInstance {
  Axios.defaults.baseURL = configuration.backofficeUrl;
  return Axios;
}

document.title = i18n?.t("title");

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <RecoilRoot
    initializeState={({ set }) => {
      // Set axios mandatory information:
      set(getAxiosInstanceAtom, () => () => initAxios());
      // Set logger mandatory information:
      set(getLoggerAtom, () => () => console);
      // Set fetcher mandatory information:
      set(fetcherModeAtom, FetcherMode.LOGGED);
      // Current locale
      set(currentLocaleAtom, configuration.defaultLocale);
      // Set custom filter for thesaurus values
      // To not display all values from this thesaurus but only some
      set(customerCommonOverrideAtom, {
        function: { ...componentThesaurusOverrideOptionsOut },
      });
    }}
  >
    <ThemeProvider theme={bouyguesTheme}>
      <CssBaseline />
      <SnackbarProvider maxSnack={3} dense>
        <RouterProvider router={router} />
      </SnackbarProvider>
    </ThemeProvider>
  </RecoilRoot>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
